.w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.w-users-userformpagewrap {
  height: 100vh;
  max-width: 340px;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}


.w-users-userformheader {
  text-align: center;
}

.w-layout-blockcontainer {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.w-form-formradioinput--inputType-custom {
  width: 12px;
  height: 12px;
  border: 1px solid #ccc;
  border-radius: 50%;
}

.w-form-formradioinput--inputType-custom.w--redirected-focus {
  box-shadow: 0 0 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom.w--redirected-checked {
  border-width: 4px;
  border-color: yellow;
}

.w-users-userloginformwrapper {
  margin-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}

.w-users-userformbutton {
  width: 100%;
  text-align: center;
}

.w-users-userformfooter {
  justify-content: space-between;
  margin-top: 12px;
  display: flex;
}

.w-users-userformerrorstate {
  margin-left: 20px;
  margin-right: 20px;
  position: absolute;
  top: 100%;
  left: 0%;
  right: 0%;
}

.w-layout-layout {
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  grid-auto-columns: 1fr;
  justify-content: center;
  padding: 10px;
}
.w-layout-layoutabout {
  grid-column-gap: 0px;
  grid-auto-columns: 1fr;
  justify-content: center;
  padding: 10px;
}

.w-layout-layoutceleb {
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  grid-auto-columns: 1fr;
  justify-content: center;
  padding-left: 10px;
  padding-right:10px;
  padding-top: 30px;
  padding-bottom: 10px;
}

.w-layout-layoutc {
  grid-row-gap: 25px;
  grid-column-gap: 25px;
  grid-auto-columns: 1fr;
  justify-content: center;
  
  padding-bottom: 0px;
}
.quick-stack-14 {
  grid-column-gap: 25px;
}

.w-layout-layoutb {
  grid-row-gap: 20px;
  grid-column-gap: 20px;

  
  padding-left: 70px;
  padding-right: 70px;
  padding-top:5px;
}

.cell-3b {
  padding-bottom:10px;
  padding-top: 10px;
}
.w-layout-layoutc {
  grid-row-gap: 20px;
  grid-column-gap: 40px;
  grid-auto-columns: 1fr;
  justify-content: center;
  padding-left: 40px;
  padding-right: 40px;
}

.w-layout-cell {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.w-layout-cellabout {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  align-items: center;
}
.spring {
  padding-top: 40px;
}

.w-users-userresetpasswordformwrapper {
  margin-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}

.w-users-userformsuccessstate {
  display: none;
}

.w-users-usersignupformwrapper {
  margin-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}

.w-checkbox {
  margin-bottom: 5px;
  padding-left: 20px;
  display: block;
}

.w-checkbox:before {
  content: " ";
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox:after {
  content: " ";
  clear: both;
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox-input {
  float: left;
  margin: 4px 0 0 -20px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  width: 12px;
  height: 12px;
  border: 1px solid #ccc;
  border-radius: 2px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-color: #3898ec;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0 0 3px 1px #3898ec;
}

.w-users-usersignupverificationmessage {
  display: none;
}

.w-checkbox-input {
  float: left;
  margin: 4px 0 0 -20px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  width: 12px;
  height: 12px;
  border: 1px solid #ccc;
  border-radius: 2px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: green;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-color: #3898ec;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0 0 3px 1px #3898ec;
}

.w-users-userupdatepasswordformwrapper {
  margin-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}

.w-users-useraccountwrapper {
  width: 100%;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
}

.w-users-blockcontent {
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  margin-bottom: 20px;
  padding: 20px;
}

.w-users-blockheader {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  justify-content: space-between;
  align-items: baseline;
  padding: 4px 20px;
  display: flex;
}

.w-users-useraccountformsavebutton {
  text-align: center;
  margin-right: 8px;
}

.w-users-useraccountformcancelbutton {
  text-align: center;
  color: #333;
  background-color: #d3d3d3;
}

@media screen and (max-width: 991px) {
  .w-layout-blockcontainer {
    max-width: 728px;
  }
}

@media screen and (max-width: 767px) {
  .w-layout-blockcontainer {
    max-width: none;
  }

  .text-block-12{
    font-size: smaller;
    padding-bottom:2px;
  }

  .w-layout-layoutb {
    padding-left: 60px;
    padding-right: 60px;
  }
  .cell-3b {
    padding-bottom:5px;
    padding-top: 5px;
  }
}

body {
  color: #1d1d1d;
  background-color: #fff;
  font-family: Inter, sans-serif;
  font-size: 16px;
  line-height: 20px;
}

h1 {
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 45px;
  font-weight: 500;
  line-height: 1.25em;
}

h2 {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 34px;
  font-weight: 500;
  line-height: 1.2em;
}

h3 {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 26px;
  font-weight: 500;
  line-height: 1.4em;
}

h4 {
  color: #33383f;
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.4em;
}

h5 {
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5em;
}

h6 {
  color: #99a4af;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 13px;
  font-weight: 700;
  line-height: 1.5em;
}

p {
  opacity: .9;
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5em;
}

a {
  color: #626a72;
  text-decoration: none;
  transition: color .2s;
}

ul {
  margin-top: 0;
  margin-bottom: 16px;
  padding-left: 20px;
}

li {
  opacity: .9;
  margin-bottom: 8px;
  font-size: 18px;
  line-height: 1.4em;
}

img {
  max-width: 100%;
  display: inline-block;
}

label {
  color: #626a72;
  margin-bottom: 6px;
  font-size: 15px;
  font-weight: 400;
  display: block;
}

em {
  letter-spacing: -.5px;
  font-style: italic;
}

blockquote {
  color: #33383f;
  border-left: 5px solid rgba(255, 1, 1, .15);
  margin-bottom: 16px;
  padding: 10px 20px;
  font-size: 18px;
  line-height: 1.4em;
}

figcaption {
  text-align: center;
  margin-top: 12px;
}

.page-wrapper {
  width: 100%;
  min-height: 80vh;
  position: relative;
}
.page-wrapper-top-padding {
  width: 100%;
  min-height: 80vh;
  padding-top: 150px;
  position: relative;
}

.page-wrapper.light-grey {
  background-color: #f5f6f7;
}

.section {
  padding-top: 80px;
  padding-bottom: 80px;
  position: relative;
}

.section_2 {
  padding-top: 25px;
  padding-bottom: 10px;
  position: relative;
}

.section.light-grey {
  background-color: #f5f6f7;
  position: relative;
}

.section.small {
  padding-top: 40px;
  padding-bottom: 40px;
}

.section.light-color-gradient {
  background-color: #f5f6f7;
  background-image: linear-gradient(54deg, rgba(255, 131, 122, .25), rgba(255, 131, 122, 0) 28%), linear-gradient(241deg, rgba(239, 152, 207, .25), rgba(239, 152, 207, 0) 36%);
  position: relative;
}

.section.daek-page-header {
  color: #fff;
  background-color: #1d1d1d;
  background-image: radial-gradient(circle at 0%, rgba(255, 1, 1, .15), rgba(0, 0, 0, 0) 47%), radial-gradient(circle at 30% 150%, rgba(122, 167, 255, .32), rgba(0, 0, 0, 0) 58%);
  padding-top: 96px;
  padding-bottom: 32px;
}

.section.black-gradient {
  color: #fff;
  background-color: #1d1d1d;
  background-image: radial-gradient(circle at 0%, rgba(255, 1, 1, .15), rgba(0, 0, 0, 0) 47%), radial-gradient(circle at 40% 130%, rgba(122, 167, 255, .41), rgba(0, 0, 0, 0) 58%);
  padding-top: 60px;
}



.black-gradient-no-padding {
  color: #fff;
  background-color: #1d1d1d;
  background-image: radial-gradient(circle at 0%, rgba(255, 1, 1, .15), rgba(0, 0, 0, 0) 47%), radial-gradient(circle at 40% 130%, rgba(122, 167, 255, .41), rgba(0, 0, 0, 0) 58%);
  padding-top: 60px;
  position: relative;
}


.section.light-page-header {
  background-color: #f5f6f7;
  background-image: radial-gradient(circle at 0%, rgba(255, 1, 1, .13), rgba(0, 0, 0, 0) 41%), radial-gradient(circle at 30% 150%, rgba(122, 167, 255, .24), rgba(0, 0, 0, 0) 54%);
  padding-top: 44px;
  padding-bottom: 22px;
}

.button {
  color: #fff;
  text-align: center;
  object-fit: fill;
  background-color: #1d1d1d;
  border-radius: 9px;
  flex: none;
  align-self: stretch;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  padding: 8px 28px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  transition: box-shadow .2s, transform .2s, border-color .2s, color .2s, background-color .2s;
  overflow: visible;
  box-shadow: 0 2px rgba(32, 32, 32, .05);
}

.button:hover {
  background-color: #33383f;
}

.button:active {
  background-color: #626a72;
  transform: translate(0, 2px);
  box-shadow: 0 0 rgba(32, 32, 32, 0);
}

.button.no-margin {
  text-align: center;
  margin-left: 0;
  margin-right: 0;
}

.button.outline {
  color: #1d1d1d;
  background-color: #fff;
  box-shadow: inset 0 0 0 1px #495158;
}

.button.outline:hover {
  background-color: #e7ecf0;
}

.button.outline:active {
  color: #fff;
  background-color: #495158;
}

.button.light {
  box-shadow: none;
  color: #626a72;
  background-color: #f5f6f7;
}

.button.light:hover {
  color: #626a72;
  background-color: #e7ecf0;
}

.button.light:active {
  color: #33383f;
  background-color: #cbd5df;
}

.button.light.mobile-hidden {
  box-shadow: inset 0 0 0 1px #e7ecf0;
}

.button.disabled {
  box-shadow: none;
  opacity: .7;
  color: #cbd5df;
  background-color: #f5f6f7;
}

.button.disabled:active {
  transform: none;
}

.button.subscribe {
  margin-bottom: 0;
  margin-left: -24px;
  padding-left: 22px;
  padding-right: 22px;
}

.button.dark {
  background-color: #495158;
}

.button.dark:hover {
  background-color: #33383f;
}

.button.dark:active {
  background-color: #99a4af;
}

.button.white {
  color: #1d1d1d;
  background-color: #fff;
  font-weight: 500;
}

.button.white:hover {
  background-color: #e7ecf0;
}

.button.white:active {
  background-color: #cbd5df;
}
.row_spacer {
  height: 1px;
  margin-left: 60px;
  margin-right: 60px;
  margin-bottom: 10px;
  margin-top:10px;
  margin-bottom: 10px;
  background-color: #e7ecf0;
}

.header_spacer {
    height: 1px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.spacer {
  height: 48px;
  background-color: #e7ecf0;
}

.spacer._64 {
  width: 64px;
  height: 64px;
  background-color: rgba(0, 0, 0, 0);
}

.spacer._32 {
  width: 32px;
  height: 32px;
  text-indent: 0%;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 6px;
}

.spacer._24 {
  width: 24px;
  height: 24px;
  text-transform: uppercase;
  background-color: rgba(0, 0, 0, 0);
}

.spacer._48 {
  width: 48px;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 6px;
}

.spacer._128 {
  width: 128px;
  height: 128px;
  background-color: rgba(0, 0, 0, 0);
}

.spacer._80 {
  width: 80px;
  height: 80px;
  background-color: rgba(0, 0, 0, 0);
}

.spacer._96 {
  width: 96px;
  height: 96px;
  height: 96px;
  background-color: rgba(94, 178, 244, 0);
}

.spacer._32 {
  height: 32px;
  background-color: rgba(0, 0, 0, 0);
}

.spacer._16 {
  width: 16px;
  height: 16px;
  background-color: rgba(0, 0, 0, 0);
  flex: none;
}

.text-box {
  max-width: 650px;
  position: relative;
}

.text-box.center-align {
  text-align: center;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
}

.text-box._550px {
  max-width: 550px;
}

.text-box._500px {
  max-width: 500px;
}

.text-box.centered {
  margin-left: auto;
  margin-right: auto;
}

._12-columns {
  flex-flow: wrap;
  align-content: stretch;
  justify-content: center;
  align-items: center;
  margin-left: -16px;
  margin-right: -16px;
  display: flex;
}

._12-columns.flex-horizontal {
  flex-wrap: nowrap;
  justify-content: flex-start;
  display: flex;
}

._12-columns.align-top {
  align-items: flex-start;
}

._12-columns.align-left {
  justify-content: flex-start;
}

.container {
  width: 100%;
  max-width: 1230px;
  min-height: 30px;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding-left: 50px;
  padding-right: 50px;
  display: block;
  position: relative;
}

.color-block {
  width: 100%;
  height: 60px;
  background-color: #1d1d1d;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-left: 0;
  margin-right: 0;
}

.color-block.grey {
  background-color: #626a72;
}

.color-block.light-grey {
  background-color: #99a4af;
}

.color-block.dark-grey {
  background-color: #33383f;
}

.color-block.back-grey {
  background-color: #f5f6f7;
  border: 1px solid #e7ecf0;
}

.color-block.soft-grey {
  background-color: #cbd5df;
}

.color-block.silver {
  background-color: #e7ecf0;
}

.color-block.white {
  background-color: #fff;
  border: 1px solid #e7ecf0;
}

.color-block.soft-red {
  background-color: rgba(255, 1, 1, .15);
  border: 1px solid #e7ecf0;
}

.color-block.soft-blue {
  background-color: rgba(122, 167, 255, .32);
  border: 1px solid #e7ecf0;
}
.text-block-12 {
  padding-bottom: 2px;
}

.color-block.soft-pink {
  background-color: rgba(239, 152, 207, .25);
  border: 1px solid #e7ecf0;
}

.white {
  color: #fff;
  font-weight: 300;
}

.column-styleguide {
  width: 100%;
  height: 40px;
  background-color: #e7ecf0;
  border-radius: 2px;
  margin-bottom: 20px;
}

.column {
  min-height: 32px;
  flex-flow: column;
  flex: 0 auto;
  align-items: stretch;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  position: relative;
}

.column.desk-10 {
  width: 83.33%;
}

.column.desk-12 {
  width: 100%;
}

.column.desk-11 {
  width: 91.66%;
}

.column.desk-6 {
  width: 50%;
}

.column.desk-5 {
  width: 41.66%;
}

.column.desk-1 {
  width: 8.33%;
  flex: 0 auto;
}

.column.desk-4 {
  width: 33.33%;
}

.column.desk-9 {
  width: 75%;
}

.column.desk-3 {
  width: 25%;
  flex: none;
}

.column.desk-2 {
  width: 16.66%;
}

.column.desk-7 {
  width: 58.3333%;
}

.column.desk-8 {
  width: 66.6667%;
}

.ds-block {
  margin-bottom: 32px;
  font-size: 18px;
}

.horizontal-line {
  width: 100%;
  height: 1px;
  background-color: #e7ecf0;
  margin-bottom: 36px;
}

.horizontal-line.design-system {
  position: relative;
  bottom: -80px;
}

.rich-text {
  text-align: left;
}

.rich-text img {
  border-radius: 5px;
}

.rich-text h4, .rich-text h3 {
  margin-top: 24px;
}

.nav-content {
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin-left: 24px;
  display: flex;
  position: relative;
}

.nav-cta-button-container {
  align-items: center;
  display: flex;
}

.menu-button.w--open {
  color: rgba(9, 106, 208, .2);
  background-color: #fff;
}

.nav-bar {
  z-index: 200;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #f5f6f7;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.logo-div {
  flex: 0 auto;
  justify-content: space-between;
  align-items: center;
  display: block;
}

.nav-logo {
  transition: opacity .2s;
  display: flex;
}

.nav-logo:hover {
  opacity: .75;
}

.footer-logo {
  margin-bottom: 20px;
}

.footer-link {
  color: #626a72;
  cursor: pointer;
  margin-bottom: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 15px;
  text-decoration: none;
  transition: color .2s ease-in-out;
  display: block;
}

.footer-link:hover {
  opacity: 1;
  color: #1d1d1d;
}

.footer-link.w--current {
  opacity: 1;
}

.footer-links-container {
  flex-direction: column;
  display: flex;
}

.footer {
  z-index: 0;
  background-color: #f5f6f7;
  padding-top: 24px;
  padding-bottom: 24px;
  position: relative;
}

.icon {
  width: 64px;
  height: 64px;
  color: #fff;
  margin-bottom: 16px;
  font-size: 24px;
}

.icon.large {
  width: 128px;
  height: 128px;
}

.search-banner {
  text-align: center;
  justify-content: space-between;
  align-items: center;
  margin-right: auto;
  padding-right: 4px;
  font-size: 14px;
  line-height: 1.4em;
  display: flex;
}

.nav-dropdown {
  color: #33383f;
  border-radius: 5px;
  margin-left: 6px;
  margin-right: 2px;
  padding: 5px 22px 5px 8px;
  font-weight: 500;
  line-height: 26px;
  transition: color .2s;
  position: relative;
}

.nav-dropdown:hover {
  opacity: 1;
  color: #1b9cca;
}

.nav-dropdown.w--current {
  color: hotpink;
  font-weight: 700;
  transition-property: none;
}

.paragraph.small {
  opacity: .75;
  margin-bottom: 0;
  padding-bottom: 16px;
  font-size: 14px;
  line-height: 1.4em;
}

.paragraph.large {
  font-size: 20px;
  line-height: 1.5em;
}

.paragraph.medium {
  font-size: 18px;
}



.paragraph.small {
  opacity: .75;
  font-size: 14px;
  line-height: 1.4em;
}

.no-margin {
  margin-top: 0;
  margin-bottom: 0;
}

.nav-menu {
  display: flex;
  text-align: right;
  margin-right: 6px;
}

.ds-menu-brand {
  width: 60px;
  margin-bottom: 50px;
  margin-left: 30px;
}

.ds-menu {
  width: 100%;
}

.ds-menu-link {
  width: 100%;
  color: #e7ecf0;
  border-left: 4px solid rgba(0, 0, 0, 0);
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 27px;
}

.ds-menu-link:hover {
  color: #cbd5df;
}

.ds-menu-link.w--current {
  color: #fff;
  background-color: #33383f;
  padding-right: 0;
  font-weight: 500;
}

.ds-nav {
  width: 240px;
  background-color: #1d1d1d;
  background-image: radial-gradient(circle at 0 100%, rgba(255, 1, 1, .15), rgba(0, 0, 0, 0) 43%);
  padding-top: 64px;
  position: fixed;
  top: auto;
  bottom: auto;
  left: 0%;
  right: auto;
  min-height: 100%;
  z-index: 0;
  overflow: auto;
}

.form-radio-button {
  margin-bottom: 16px;
  padding-left: 24px;
}

.radio-button {
  width: 20px;
  height: 20px;
  margin-top: 0;
  margin-left: -24px;
  margin-right: 10px;
}

.radio-button.w--redirected-checked {
  border-width: 6px;
  border-color: #197dff;
}

.form-error {
  color: #1d1d1d;
  background-color: #e7ecf0;
  border-radius: 6px;
  padding: 16px;
  font-size: 14px;
  line-height: 18px;
}

.form {
  text-align: left;
  margin-bottom: 0;
}

.checkbox-field {
  flex-flow: wrap;
  justify-content: flex-start;
  align-items: stretch;
  margin-bottom: 16px;
  padding-left: 24px;
  display: flex;
  position: relative;
}

.check-box {
  width: 14px;
  height: 14px;
  margin-top: 2px;
  margin-left: -24px;
  margin-right: 10px;
}

.check-box.w--redirected-checked {
  background-color: #197dff;
  background-position: 50%;
  background-size: 16px;
  border-color: #197dff;
}

.ds-content {
  max-width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 240px;
  display: flex;
  position: relative;
}

.form-success {
  width: 100%;
  color: #33383f;
  background-color: #e7ecf0;
  border-radius: 8px;
  padding: 16px 32px;
  font-size: 16px;
  line-height: 1.4em;
}

.form-success.dark {
  color: #fff;
  background-color: #495158;
}

.ds-section {
  z-index: 0;
  text-align: left;
  padding: 160px 80px 0px 60px;
  position: relative;
}

.ds-section.header {
  color: #1d1d1d;
  background-color: #f5f6f7;
  margin-top: -100px;
}

.ds-title {
  color: #33383f;
  margin-bottom: 8px;
}

.ds-section-header {
  margin-bottom: 0px;
}

.text-field {
  height: auto;
  min-width: 120px;
  background-color: #f5f6f7;
  border: 1px solid #e7ecf0;
  border-radius: 9px;
  margin-bottom: 16px;
  padding: 19px 12px;
  font-size: 15px;
  line-height: 1.4em;
  transition: border-color .2s;
  color: #cbd5df;
}

.errorHelperText {
  color: #d32f2f;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 0px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 7px;
  background-color: white;
}


.text-field:focus {
  border-color: #33383f;
}
.text-field::-webkit-datetime-edit{
  color: black;
}
.text-field::-ms-input-placeholder {
  color: #cbd5df;
}

.text-field::placeholder {
  color: #cbd5df;
}

.text-field.no-margin {
  margin-bottom: 0;
}

.ebook-box {
  width: 100%;
  height: 100%;
  color: #1d1d1d;
  text-align: left;
  border-radius: 4px;
  flex-direction: column;
  transition: transform .2s, opacity .2s;
  overflow: hidden;
}

.ebook-box:hover {
  transform: translate(0, -3px);
}

.ebook-thumbnail {
  border-radius: 8px;
  margin-bottom: 16px;
  overflow: hidden;
}

.text-area {
  min-height: 120px;
  background-color: #f5f6f7;
  border: 1px solid #e7ecf0;
  border-radius: 6px;
  margin-bottom: 16px;
  padding: 16px;
  font-size: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .01);
}

.text-area::-ms-input-placeholder {
  color: #cbd5df;
}

.text-area::placeholder {
  color: #cbd5df;
}

.tabs-menu {
  width: 100%;
  justify-content: center;
  margin-bottom: 48px;
  display: flex;
}

.vertical-tab {
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
}

.tab-link {
  opacity: .5;
  color: #626a72;
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 2px solid #e7ecf0;
  flex: 0 auto;
  margin-left: 0;
  margin-right: 0;
  padding: 15px 0;
  font-size: 18px;
}

.tab-link:hover {
  color: #626a72;
  border-bottom-color: #cbd5df;
}

.tab-link.w--current {
  opacity: 1;
  color: hotpink;
  background-color: rgba(0, 0, 0, 0);
  border-bottom-color: #197dff;
  font-weight: 600;
}

.ds-description {
  max-width: 500px;
  color: #626a72;
}

.small-button {
  color: #fff;
  text-align: center;
  background-color: #1d1d1d;
  border-radius: 8px;
  flex: none;
  margin-bottom: 16px;
  padding: 5px 16px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.5;
  transition: transform .2s, border-color .2s, color .2s, box-shadow .2s, background-color .2s;
  box-shadow: 0 1px rgba(0, 0, 0, .06);
}

.small-button:hover {
  color: #fff;
  background-color: #33383f;
}

.small-button:active {
  background-color: #495158;
  transform: translate(0, 1px);
  box-shadow: 0 0 rgba(0, 0, 0, .06);
}

.small-button.light {
  box-shadow: none;
  color: #626a72;
  background-color: #f5f6f7;
  margin-right: 0;
}

.small-button.light:hover {
  background-color: #e7ecf0;
}

.small-button.light:active {
  color: #33383f;
  background-color: #cbd5df;
}

.small-button.dark {
  box-shadow: none;
  background-color: #495158;
  margin-right: 0;
}

.small-button.dark:hover {
  background-color: #33383f;
}

.small-button.dark:active {
  background-color: #1d1d1d;
}

.nav-container {
  width: 100%;
  height: 70px;
  max-width: 1230px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 50px;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
}

.error-2 {
  flex-wrap: wrap;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  align-items: center;
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
}

.nav-link {
  color: #626a72;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  margin-left: 4px;
  margin-right: 4px;
  padding: 6px 8px;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  transition: background-color .2s, transform .2s, color .2s;
}

.nav-link:hover {
  color: #1d1d1d;
}

.nav-link:active {
  color: #626a72;
}

.nav-link.w--current {
  color: #1d1d1d;
}

.nav-link.cta-button {
  color: #fff;
  background-color: #1d1d1d;
  border-radius: 8px;
  padding-left: 20px;
  padding-right: 20px;
  transition: box-shadow .2s, background-color .2s, transform .2s, color .2s;
  box-shadow: 0 2px #f5f6f7;
}

.nav-link.cta-button:hover {
  background-color: #33383f;
}

.nav-link.cta-button:active {
  background-color: #626a72;
  transform: translate(0, 1px);
  box-shadow: 0 0 #e7ecf0;
}

.form-dropdown {
  width: 100%;
  height: 44px;
  color: #99a4af;
  background-color: #f5f6f7;
  border: 1px solid #e7ecf0;
  border-radius: 6px;
  margin-bottom: 16px;
  padding: 8px 12px;
  font-size: 15px;
  position: relative;
}

.form-dropdown:focus {
  color: #37474e;
}

.form-dropdown::-ms-input-placeholder {
  color: #999;
  font-size: 15px;
}

.form-dropdown::placeholder {
  color: #999;
  font-size: 15px;
}

.field-block {
  z-index: 1;
  min-height: 20px;
  min-width: 50px;
  border: 1px solid rgba(0, 0, 0, 0);
  flex-direction: column;
  flex: 1;
  align-items: stretch;
  margin-bottom: 8px;
  display: flex;
}

.error {
  flex-wrap: wrap;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  align-items: center;
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
}

.footer-header {
  color: #99a4af;
  letter-spacing: .6px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
}

.footer-container {
  width: 100%;
  max-width: 1230px;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 50px;
  display: block;
}

.ds-colour-block {
  width: 12.5%;
  padding-right: 16px;
  display: inline-block;
}

.ds-colour-grid {
  flex-wrap: wrap;
  margin-top: 24px;
  display: flex;
}

.ds-module {
  min-height: 100px;
  min-width: 100px;
  background-color: #e7ecf0;
  border-radius: 2px;
  flex-direction: column;
  display: flex;
  position: relative;
}

._1-2-grid {
  width: 100%;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  flex-direction: column;
  grid-template-rows: auto;
  grid-template-columns: 1fr 2fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  align-items: stretch;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  position: relative;
}

._2-1-grid {
  width: 100%;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  flex-direction: column;
  grid-template: "Area Area-2"
                 ". ."
                 "Area-3 ."
                 / .5fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  align-items: stretch;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  position: relative;
}

.heading {
  font-weight: 600;
  display: block;
}

.heading.h1 {
  margin-bottom: 18px;
  font-size: 46px;
  line-height: 1.25em;
}

.heading.h1.small-margin {
  margin-bottom: 6px;
}

.heading.h2 {
  margin-bottom: 16px;
  font-size: 36px;
  line-height: 1.3em;
}

.heading.h3 {
  margin-bottom: 12px;
  font-size: 26px;
  line-height: 1.4em;
}

.heading.h3.no-margin {
  margin-bottom: 0;
}

.heading.h4 {
  margin-bottom: 12px;
  font-size: 22px;
  line-height: 1.45em;
}

.heading.h5 {
  color: #33383f;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5em;
}

.heading.h6 {
  color: #626a72;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.5em;
}

.heading.large-h1 {
  margin-bottom: 16px;
  font-size: 64px;
  line-height: 1.15em;
}

.error-3 {
  width: 100%;
  max-width: 1230px;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding-left: 40px;
  padding-right: 40px;
  display: block;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
}

.licenses-grid {
  grid-column-gap: 96px;
  grid-row-gap: 24px;
  flex-direction: column;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  position: relative;
}

.utility-page-wrap {
  width: 100vw;
  max-height: 100%;
  max-width: 100%;
  min-height: auto;
  color: #fff;
  background-color: #1d1d1d;
  background-image: radial-gradient(circle at 50% 0, rgba(239, 152, 207, .2), rgba(0, 0, 0, 0) 57%), radial-gradient(circle at 0 20%, rgba(122, 167, 255, .25), rgba(0, 0, 0, 0) 42%);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
}


.utility-page-wrap.center-align {
  text-align: center;
  background-image: radial-gradient(circle at 50% -20%, rgba(239, 152, 207, .2), rgba(0, 0, 0, 0) 61%), radial-gradient(circle at 0 20%, rgba(122, 167, 255, .32), rgba(0, 0, 0, 0) 42%);
}

.utility-page-content {
  width: 380px;
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.utility-page-form {
  width: 100%;
  max-width: 400px;
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.visible-spacer {
  background-color: #e7ecf0;
  border-radius: 2px;
  margin-bottom: 24px;
  display: inline-block;
}

.email-form {
  max-width: 450px;
  flex-direction: row;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.email-form.center-align {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.section-top {
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 24px;
  display: flex;
}

._3-grid {
  width: 100%;
  grid-column-gap: 8px;
  grid-row-gap: 32px;
  grid-template: "Area Area-3 Area-2"
  / 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  padding-top: 0;
  display: grid;
}

._4-grid {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.email-subscribe {
  width: 100%;
  grid-column-gap: 12px;
  justify-content: center;
  align-items: flex-start;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: relative;
}

.search {
  align-items: flex-start;
  margin-bottom: 0;
}

.terms-card {
  z-index: 2;
  max-width: 800px;
  background-color: #fff;
  border-radius: 12px;
  margin-top: -180px;
  margin-left: auto;
  margin-right: auto;
  padding: 80px 64px 48px;
  position: relative;
  box-shadow: 0 3px 12px rgba(0, 0, 0, .06);
}

.popular-posts-grid {
  width: 100%;
  grid-column-gap: 80px;
  grid-row-gap: 64px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.container-2 {
  z-index: 2;
  width: 100%;
  max-width: 1280px;
  min-height: 50px;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding-left: 40px;
  padding-right: 40px;
  display: block;
  position: relative;
}

.hidden {
  display: none;
}

.search-bar {
  width: 280px;
  height: 36px;
  color: #1d1d1d;
  background-color: #f5f6f7;
  background-image: url('../images/MagnifyingGlass-Grey.svg');
  background-position: 8px;
  background-repeat: no-repeat;
  background-size: 18px;
  border: 1px solid #f5f6f7;
  border-radius: 6px;
  margin-bottom: 0;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 32px;
  font-size: 15px;
  font-weight: 400;
  transition: border-color .2s;
}

.search-bar:hover, .search-bar:focus {
  border-color: #cbd5df;
}

.search-bar::-ms-input-placeholder {
  color: #99a4af;
}

.search-bar::placeholder {
  color: #99a4af;
}

.search-section {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.card-text-link {
  width: 100%;
  max-width: 450px;
  color: #1d1d1d;
  text-align: left;
  border-radius: 4px;
  flex-direction: column;
  transition: transform .2s, opacity .2s;
  overflow: hidden;
}

.card-text-link:hover {
  color: #33383f;
}

.image-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.form-card {
  color: #1d1d1d;
  text-align: left;
  background-color: #fff;
  border-radius: 12px;
  padding: 40px;
}

.title-tag {
  color: #99a4af;
  margin-bottom: 12px;
  font-weight: 500;
}

.cta-banner {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  text-align: left;
  border-top: 1px solid #e7ecf0;
  border-bottom: 1px solid #e7ecf0;
  flex-direction: row;
  grid-template-rows: auto;
  grid-template-columns: 1fr auto;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 32px;
  display: grid;
  position: relative;
  overflow: hidden;
}

.error-4 {
  width: 100%;
  max-width: 1230px;
  min-height: 50px;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding-left: 50px;
  padding-right: 50px;
  display: block;
  position: relative;
}

.grey-text-link {
  color: #626a72;
  border-bottom: 1px solid #cbd5df;
}

.grey-text-link:hover {
  color: #33383f;
  border-bottom-color: #99a4af;
}

.banner-section {
  z-index: 200;
  background-color: #fff;
  background-image: radial-gradient(circle at 25% -20%, rgba(122, 167, 255, .15), rgba(255, 255, 255, 0) 52%), radial-gradient(circle at 100% 0, rgba(255, 1, 1, .08), rgba(255, 255, 255, 0) 25%);
  padding-left: 50px;
  padding-right: 50px;
  position: relative;
}

.banner-container {
  max-width: 1080px;
  flex: 1;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.banner {
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.4em;
  display: flex;
}

.banner-link {
  color: #99a4af;
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  line-height: 1.3em;
  text-decoration: none;
  display: inline;
}

.banner-link:hover {
  color: #1d1d1d;
}

.hero-grid {
  grid-template-rows: auto;
  grid-template-columns: 1.4fr 1fr;
  align-items: center;
}

.hero-illustration {
  mix-blend-mode: multiply;
  margin-left: auto;
  margin-right: auto;
}

.large-3-grid {
  width: 100%;
  grid-column-gap: 48px;
  grid-row-gap: 32px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.footer-grid {
  grid-template-rows: auto;
  grid-template-columns: 3fr 1fr 1fr .8fr;
}

.footer-logo-block {
  max-width: 650px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.ebook-header-block {
  height: 50px;
  max-width: 650px;
  position: relative;
}

.ebook-page-grid {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 400px;
}

.ebook-cover {
  border-radius: 8px;
  margin-bottom: 16px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, .05);
}

.ebook-mobile-block {
  height: 50px;
  max-width: 650px;
  display: none;
  position: relative;
}

.full-page-wrapper {
  width: 100%;
  max-width: none;
  color: #fff;
  background-color: #1d1d1d;
  background-image: radial-gradient(circle at 100% 100%, rgba(122, 167, 255, .32), rgba(0, 0, 0, 0) 44%), radial-gradient(circle at 0 100%, rgba(255, 1, 1, .15), rgba(0, 0, 0, 0) 32%);
  align-items: center;
  padding: 24px 12px;
}

.form-page-logo {
  width: 190px;
}

.form-card-header {
  text-align: center;
  margin-bottom: 32px;
}

.admin-form-card {
  width: 100%;
  max-width: 380px;
  color: #1d1d1d;
  background-color: #fff;
  border-radius: 12px;
  padding: 40px;
}

.admin-form-card.center-align {
  text-align: center;
}

.form-card-footer {
  grid-column-gap: 8px;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 14px;
}

.below-card-link {
  color: #99a4af;
  margin-top: 24px;
  font-size: 14px;
  display: inline-block;
}

.below-card-link:hover {
  color: #cbd5df;
}

.checkbox-label {
  flex: 1;
  font-size: 14px;
  display: inline-block;
}

.white-text-link {
  color: #fff;
  border-bottom: 1px solid #626a72;
  transition: border-color .2s, color .2s;
}

.white-text-link:hover {
  color: #cbd5df;
  border-bottom-color: #cbd5df;
}

.field-label {
  color: #202020;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.3em;
}

.field-label.no-margin {
  margin-bottom: 0;
}

.account-header {
  border-style: none none solid;
  border-bottom-color: #e7ecf0;
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}

.account-info-wrapper {
  margin-top: 24px;
}

.account-card {
  width: 100%;
  max-width: 800px;
  color: #1d1d1d;
  background-color: #fff;
  border-radius: 12px;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.account-page-wrapper {

  max-height: 100%;
  max-width: 100%;
  color: #fff;
  background-color: #e7ecf0;
  background-image: radial-gradient(circle at 50% 0, rgba(239, 152, 207, .2), rgba(0, 0, 0, 0) 57%), radial-gradient(circle at 0 20%, rgba(122, 167, 255, .25), rgba(0, 0, 0, 0) 42%);
  justify-content: center;
  align-items: center;
  display: flex;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
  padding-bottom:50px;
  
}

.form-2-grid {
  grid-column-gap: 24px;
  grid-row-gap: 0px;
  grid-template-rows: auto;
}

.verification-box {
  text-align: center;
}

.form-page-logo-link {
  margin-bottom: 32px;
  transition: opacity .2s, color .2s;
}

.form-page-logo-link:hover {
  opacity: .9;
}

.list {
  margin-bottom: 0;
  padding-top: 0;
  list-style-type: none;
}

.container-3 {
  min-height: 5px;
}

.container-4, .container-5, .container-6, .container-7 {
  height: 5px;
}

.text-block {
  border: 1px #000;
  border-radius: 0;
}

.text-block-2, .text-block-3 {
  border: 1px #000;
}

.dropdown {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.quick-stack {
  grid-column-gap: 0px;
  grid-row-gap: 20px;
}

.container-8 {
  object-fit: fill;
}

.container-9, .container-10 {
  min-height: 6px;
}

.quick-stack-2 {
  padding-bottom: 0;
}

.heading-2 {
  object-fit: fill;
  overflow: auto;
}

.person-header {
  grid-column-gap: 10px;
}

.quick-stack-3 {
  grid-row-gap: 10px;
}

.heading-3 {
  align-self: flex-start;
}

.quick-stack-4 {
  align-self: flex-start;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}

.text-block-4 {
  align-self: flex-start;
}

.quick-stack-5 {
  padding-top: 3px;
  padding-left: 3px;
  object-fit: fill;
  
}


.quick-stack-6 {
  padding: 3px;
}

.quick-stack-7 {
  grid-row-gap: 3px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.quick-stack-7.cell-2 {
  grid-row-gap: 3px;
}

.quick-stack-8about {
  grid-row-gap: 20px;
}




.cell {
  align-items: stretch;
}

.quick-stack-9 {
  padding: 0;
}

.cell-2 {
  align-items: stretch;
  padding-left: 20px;
  padding-right:20px;
}
.cell-2b {
  align-items: stretch;
  padding-left: 0px;
  padding-right:0px;
}

.cell-3 {
  align-items: stretch;
  padding: 20px;
}

.quick-stack-10 {
  grid-row-gap: 3px;
  padding: 10px 20px 10px 10px;
}

.quick-stack-11 {
  grid-row-gap: 3px;
  align-self: stretch;
  padding: 10px;
}

.cell-4, .cell-5 {
  align-items: stretch;
}

.text-block-5 {
  align-self: stretch;
}

.cell-6 {
  padding: 20px;
}

.image {
  align-self: auto;
}

.text-block-6, .text-block-7 {
  padding-left: 20px;
}

.cell-7, .cell-8 {
  align-items: stretch;
}

.quick-stack-12 {
  padding-left: 0;
}

.cell-9 {
  margin-top: 20px;
  padding-left: 0px;
}

.list-2 {
  align-self: stretch;
  list-style-type: decimal;
}

.cell-10 {
  justify-content: flex-end;
}

.cell-23 {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  padding: 20px;
}

.quick-stack-13 {
  padding-top: 0;
}

.list-3 {
  list-style-type: decimal;
}

.heading-5, .list-4, .heading-6, .text-block-12, .text-block-13, .text-block-14 {
  align-self: stretch;
}

.text-block-14 {
  padding-top: 5px;
}

.cell-11 {
  justify-content: flex-end;
}

.quick-stack-14 {
  grid-row-gap: 0px;
}

.quick-stack-15 {
  padding-top: 0px;
  padding-left: 0;
}

.text-block-15 {
  color: #495158;
}

.form-2 {
  padding-top: 20px;
}



.heading-7, .container-11 {
  text-align: center;
}

.link-3 {
  align-self: center;
}

.link-4 {
  color: #99a4af;
  -webkit-text-stroke-color: #99a4af;
  align-self: center;
  padding-top: 20px;
}

.quick-stack-17 {
  padding-bottom: 0;
}

.cell-14 {
  align-items: stretch;
}

.quick-stack-18 {
  align-self: flex-start;
  padding: 0;
}

.text-block-16 {
  padding-bottom: 10px;
}

.link-5 {
  text-align: center;
}

.heading-8 {
  padding-top: 20px;
}

.text-block-17 {
  align-self: center;
  padding-left: 0;
  font-size: 20px;
}

@media screen and (min-width: 1280px) {

  
  .container {
    grid-column-gap: 25px;
  }

  ._3-grid {
    grid-column-gap: 8px;
    grid-template-areas: "Area Area-3 Area-2";
  }

  .banner-container {
    max-width: 1140px;
  }

  .list {
    padding-left: 10px;
  }

  .person-header {
    grid-column-gap: 14px;
  }
}

@media screen and (max-width: 991px) {
  .button {
    position: relative;
  }

  ._12-columns {
    flex-flow: wrap;
  }

  .container {
    padding-left: 30px;
    padding-right: 30px;
  }

  .column {
    width: 50%;
  }

  .column.desk-3 {
    width: 33.33%;
    flex-wrap: nowrap;
  }

  .ds-block {
    margin-bottom: 40px;
  }

  .menu-icon {
    color: hotpink;
  }

  .nav-content {
    justify-content: flex-end;
    margin-left: 16px;
  }

  .menu-button.w--open {
    color: yellow;
    background-color: rgba(0, 0, 0, 0);
  }

  .logo-div {
    flex: 0 auto;
  }

  .nav-logo {
    flex: 1;
  }

  .footer-links-container {
    flex-direction: column;
    align-items: flex-start;
    display: flex;
  }

  .search-banner {
    display: none;
  }

  .nav-dropdown {
    text-align: center;
    flex: 1;
    margin-left: 0;
    margin-right: 0;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 15px;
    display: block;
  }

  .ds-menu-brand {
    margin-bottom: 0;
    top: 5px;
  }

  .ds-menu {
    z-index: 500;
    background-color: yellow;
    border-bottom: 1px solid #e7ecf0;
    padding-top: 16px;
    padding-bottom: 16px;
    display: block;
    position: absolute;
    top: 80px;
    bottom: auto;
    left: 0%;
    right: auto;
  }
  .ds-menu-link {
    color: #495158;
  }
  .ds-nav {
    z-index: 10;
    width: 100%;
    padding-top: 20px;
    position: -webkit-sticky;
    position: sticky;
    bottom: auto;
    left: auto;
    top: 70px;
    overflow: visible;
  }

  .ds-content {
    margin-left: 0;
    position: static;
  }

  .ds-section {
    padding-left: 30px;
    padding-right: 30px;
  }

  .small-button {
    position: relative;
  }

  .nav-container {
    padding-left: 30px;
    padding-right: 30px;
  }

  .nav-link {
    margin-left: 0;
    margin-right: 0;
    font-size: 15px;
  }

  .nav-link.cta-button {
    padding-left: 16px;
    padding-right: 16px;
  }

  .footer-container {
    padding-left: 30px;
    padding-right: 30px;
  }

  ._1-2-grid, ._2-1-grid {
    margin-left: 0;
  }

  .heading.h1 {
    font-size: 40px;
  }

  .error-3 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .licenses-grid {
    grid-column-gap: 40px;
    margin-left: 0;
  }

  .ds-body {
    flex-direction: column;
    display: flex;
  }

  .ds-menu-button {
    color: #fff;
    margin-right: 12px;
    top: -10px;
  }

  .ds-menu-button.w--open {
    background-color: #197dff;
    border-radius: 40px;
  }

  ._3-grid {
    grid-column-gap: 8px;
    grid-template-columns: 1fr 1fr;
  }

  ._4-grid {
    grid-template-columns: 1fr 1fr;
  }

  .terms-card {
    padding-top: 64px;
  }

  .popular-posts-grid {
    grid-template-columns: 1fr;
  }

  .container-2 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .search-bar {
    width: 220px;
    font-size: 14px;
  }

  .error-4 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .banner-section {
    padding-left: 60px;
    padding-right: 60px;
  }

  .banner-container {
    text-align: center;
  }

  .large-3-grid {
    grid-template-columns: 1fr 1fr;
  }

  .footer-grid {
    grid-template-columns: 2fr 1fr 1fr .8fr;
  }

  .quick-stack-5 {
    padding-bottom: 3px;
    padding-right: 3px;
  }
}

@media screen and (max-width: 767px) {
  
  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 32px;
  }

  h3 {
    font-size: 22px;
  }

  p {
    font-size: 15px;
  }

  .section {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .section.daek-page-header, .section.light-page-header {
    padding-top: 60px;
    padding-bottom: 16px;
  }

  .button.light.mobile-hidden {
    display: none;
  }

  .spacer {
    height: 32px;
  }

  .spacer._64 {
    height: 48px;
  }

  .spacer._80 {
    width: 64px;
    height: 64px;
  }

  ._12-columns {
    flex-wrap: wrap;
  }

  ._12-columns.flex-horizontal {
    flex-flow: wrap;
    margin-bottom: 0;
  }

  .column.desk-6, .column.desk-5, .column.desk-4, .column.desk-9, .column.desk-7, .column.desk-8 {
    width: 100%;
  }

  .nav-content {
    background-color: #fff;
    border-top: 1px solid #e7ecf0;
    margin-left: 0;
    padding-top: 20px;
    padding-bottom: 40px;
    position: absolute;
    overflow: auto;
  }

  .nav-cta-button-container {
    flex-direction: column;
    align-items: stretch;
    margin-top: 10px;
  }

  .menu-button {
    width: 56px;
    height: 56px;
    justify-content: center;
    align-items: center;
    margin-right: -12px;
    padding: 16px;
  }

  .nav-bar {
    border: 1px #000;
  }

  .logo-div {
    margin-right: auto;
  }

  .footer-logo {
    padding-left: 0;
  }

  .footer-links-container {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 24px;
    display: flex;
  }

  .nav-dropdown {
    padding-top: 13px;
    padding-bottom: 13px;
    font-size: 16px;
  }
  .account-card {
    padding: 30px 30px;
  }

  .nav-dropdown:hover {
    transform: none;
  }

  .paragraph {
    font-size: 15px;
  }

  .nav-menu {
    text-align: center;
    margin-right: 0;
    padding-right: 0;
  }

  .ds-menu-brand.w--current {
    margin-top: 14px;
  }

  .ds-content {
    margin-left: 0;
  }

  .ds-section {
    padding-left: 30px;
    padding-right: 30px;
  }

  .tabs-menu {
    flex-wrap: wrap;
  }

  .tab-link {
    width: auto;
  }

  .nav-container {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .nav-link {
    text-align: center;
    margin-left: 25px;
    margin-right: 25px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 16px;
  }

  .nav-link:hover {
    transform: none;
  }

  .field-block {
    min-height: auto;
  }

  .footer-container {
    flex-direction: column;
  }

  .ds-colour-block {
    width: 25%;
  }

  ._1-2-grid, ._2-1-grid {
    grid-template-columns: 2fr;
    margin-left: 0;
  }

  .heading.h1 {
    font-size: 35px;
  }

  .heading.h2 {
    margin-bottom: 16px;
    font-size: 28px;
  }

  .heading.h4 {
    font-size: 20px;
  }

  .heading.large-h1 {
    font-size: 48px;
  }

  
  .licenses-grid {
    grid-template-columns: 1fr;
    margin-left: 0;
  }

  .section-top {
    text-align: center;
  }

  ._3-grid {
    grid-column-gap: 8px;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }

  .terms-card {
    padding-top: 48px;
    padding-left: 48px;
    padding-right: 48px;
  }

  .popular-posts-grid {
    grid-template-columns: 1fr;
  }

  .container-2 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .search-bar {
    width: 100%;
  }

  .cta-banner {
    text-align: center;
    flex-direction: column;
    grid-template-columns: auto;
    padding: 40px;
  }

  .banner-section {
    text-align: left;
    justify-content: flex-start;
    padding-left: 30px;
    padding-right: 30px;
  }

  .banner-container {
    text-align: left;
  }

  .banner {
    text-align: left;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .hero-grid {
    grid-template-columns: 1fr;
  }

  .hero-illustration {
    width: 120px;
    margin-left: 0;
  }

  .large-3-grid {
    grid-template-columns: 1fr;
  }

  .footer-grid {
    grid-template-columns: 1fr .8fr;
  }

  .footer-logo-block {
    margin-bottom: 40px;
  }

  .ebook-header-block {
    height: auto;
    display: none;
  }

  .ebook-page-grid {
    grid-template-columns: 1fr;
  }

  .ebook-mobile-block {
    height: auto;
    display: block;
  }

  
}

@media screen and (max-width: 479px) {
  .account-page-wrapper {
    
    padding-top: 50px;
    padding-bottom:40px;
    padding-right: 20px;
    padding-left:20px;
  }
  .ds-nav {
    top: 64px;
  }

  .w-layout-layoutc {
    padding-left:0px;
    padding-right:0px;
    grid-column-gap: 5px;
  }

  .w-layout-layoutd {
    padding-left:10px;
    padding-right:10px;
  }

  .text-block-12{
    font-size: smaller;
    padding-bottom:2px;
  }

  body {
    font-size: 14px;
  }

  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 28px;
  }

  h4 {
    font-size:15px;
  }

  h6 {
    font-size:12px;
  }

  .button {
    width: 100%;
    font-size: 16px;
    line-height: 1.8em;
  }

  .button.subscribe {
    margin-left: 0;
  }

  ._12-columns.flex-horizontal {
    flex-direction: row;
  }

  .container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .color-block {
    margin-bottom: 20px;
  }

  .column {
    margin-bottom: 0;
  }

  .column.desk-6, .column.desk-5, .column.desk-3 {
    width: 100%;
  }

  .menu-button {
    flex: 0 auto;
  }

  .search-banner {
    justify-content: space-between;
  }

  .paragraph.medium {
    font-size: 16px;
  }

  .ds-menu-brand {
    margin-left: 16px;
  }

  .ds-section {
    padding-left: 16px;
    padding-right: 16px;
  }

  .text-field, .text-area {
    width: 100%;
  }

  .nav-container {
    height: 64px;
    padding: 2px 20px;
  }

  .footer-container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .heading.large-h1 {
    font-size: 42px;
  }

  .error-3 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .ds-menu-button {
    margin-right: 6px;
  }

  .email-form {
    width: 100%;
    flex-direction: column;
  }

  ._3-grid {
    grid-column-gap: 4px;
    grid-template-columns: 1fr;
  }

  ._4-grid {
    grid-template-columns: 1fr;
  }

  .email-subscribe {
    width: 100%;
    grid-row-gap: 12px;
    flex-direction: column;
  }

  .search {
    flex-direction: column;
  }

  .terms-card {
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .container-2 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .search-bar {
    width: 100%;
  }

  .form-card {
    padding: 24px;
  }

  .title-tag {
    margin-bottom: 6px;
  }

  .cta-banner {
    padding: 32px;
  }

  .error-4 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .banner-section {
    margin-left: 0;
    margin-right: 0;
    padding-left: 20px;
    padding-right: 20px;
  }

  .banner {
    text-align: left;
    justify-content: space-between;
  }

  .banner-link {
    width: 100%;
    flex: 0 auto;
  }

  .form-page-logo {
    width: 160px;
  }

  .admin-form-card {
    padding: 28px;
  }

  .account-card {
    padding: 10px 10px;
    padding-top: 20px;
  }



  .form-2-grid {
    grid-template-columns: 1fr;
  }

  .form-page-logo-link {
    margin-bottom: 24px;
  }

  .chart {
    grid-column-gap: 5px;
    grid-row-gap: 20px;
    
    
  }

  .quick-stack-8 {
    grid-column-gap: 15px;
    grid-row-gap: 0px;
  }

  

  .w-layout-layoutb {
    padding-left: 30px;
    padding-right: 30px;
  }
  .cell-3b {
    padding-bottom:0px;
    padding-top: 0px;
  }

  .cell-2 {
    align-items: stretch;
 
  }
  .cell-2b {
    align-items: stretch;
    padding-left:10px;
    
  }

  .quick-stack-10 {
    padding-left: 0;
    padding-right: 0;
  }

  .quick-stack-12 {
    padding-right: 0;
    overflow: auto;
    width: 100%
  }
  .quick-stack-15 {
    object-fit:scale-down;
    grid-column-gap: 10px;
  }

  .w-layout-blockcontainer {
    
    
    display: block;
  }

  .heading-4 {
    object-fit: scale-down;
    align-self: auto;
    padding-left: 20px;
  }

  .text-block-8, .text-block-9, .text-block-10, .text-block-11 {
    padding-top: 10px;
  }

  .text-block-10b {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .text-block-8 {
    padding-top: 100px;
  }
  .heading-6 {
    padding-left: 0px;
  }

  .text-block-14 {
    padding-top: 5px;
  }

  .quick-stack-14 {
    grid-column-gap: 2px;
    
  }
  .buttonContainer {
    margin-left: 20px;
    margin-right: 20px;
    padding-top:20px;
    padding-bottom:20px;
  }
  .image-2, .image-3, .image-4, .image-5 {
    flex: 0 auto;
  }

  .cell-12 {
    padding-right: 10px;
  }

  .cell-13 {
    
    padding-left: 0px;
  }

  .list-item, .link, .link-2 {
    font-size: 13px;
  }

  .list-item-2 {
    font-size: 14px;
  }
}

#w-node-_2c511369-eabb-a0ff-6024-6e8ecffbdf82-7816026e, #w-node-b49c48ad-b3b4-f319-67e3-9d04be5835dc-7816029e, #Day.w-node-_129da1bc-4f18-3346-2b09-555434cf9019-7816029e, #Year.w-node-f66e0f00-5544-d086-2de2-f069ec8113b2-7816029e {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#Month.w-node-de9b1daf-796e-4c17-7675-f12bed2deb05-7816029e {
  grid-area: Area;
}

#w-node-_3385dc3a-c504-929a-7217-463586dd5d8a-7816029e {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: auto;
  justify-self: auto;
}

#Minute.w-node-c1f8206c-7fea-4086-4ce5-3e8eee400534-7816029e {
  grid-area: Area-3;
}

#Hour.w-node-_113deca7-aadc-61b1-2899-343f9a154bd7-7816029e {
  grid-area: Area;
}

#w-node-_0a57f092-020e-a403-18f2-e840acc27a6b-781602a5, #w-node-_0a57f092-020e-a403-18f2-e840acc27a6d-781602a5 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_949f8a09-c801-4d94-6a9c-b7c17398c2db-781602ad {

  grid-template-columns: 1fr 1fr;
}

#w-node-_949f8a09-c801-4d94-6a9c-b7c17398c2dc-781602ad, #w-node-_949f8a09-c801-4d94-6a9c-b7c17398c2dd-781602ad, #w-node-bb2ce7be-e12f-8ceb-1723-aca2a8fb17d7-781602ad, #w-node-_16f91801-6031-503b-af9e-490e5b6bf78f-781602ad, #w-node-b01332d0-5821-6bcd-5851-9ab8b01698b9-781602ad, #w-node-f26fe889-5d68-857c-93d0-d3e23ec5d4a0-781602ad, #w-node-_2b7726ca-481b-5750-3a9b-5e3c411ccd46-781602ad, #w-node-_9b4dadfb-a1ee-719e-b8a7-2edcae84dab0-781602ad, #w-node-beb95278-f824-193c-d93c-522431bd3a57-781602ad, #w-node-_8b6e34ed-7ba5-6ade-59fe-f2e468ee84c3-781602ad, #w-node-_16122fcd-aa74-e2ba-fa04-ff8e3e3bf85c-781602ad, #w-node-aa6b46a0-dd21-938a-c6c5-807c7f98fee4-781602ad {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-f5e8a229-355b-48df-57fd-a9c5d4fffee4-781602ad {
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
}

#w-node-f5e8a229-355b-48df-57fd-a9c5d4fffee5-781602ad, #w-node-f5e8a229-355b-48df-57fd-a9c5d4fffee6-781602ad, #w-node-_1ef9daa2-4f8c-6e1c-0008-68c6b39e6aa7-781602ad, #w-node-_56926274-e07f-9d27-17a9-c637dd29efaa-781602ad {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_35a82d6c-2e64-22b2-490a-69d1a9de2096-781602ad {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
}

#w-node-_35a82d6c-2e64-22b2-490a-69d1a9de2097-781602ad, #w-node-_35a82d6c-2e64-22b2-490a-69d1a9de2098-781602ad {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_281ae0c8-c087-7d59-5ada-d56a51f2f630-781602ad {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
}

#w-node-_281ae0c8-c087-7d59-5ada-d56a51f2f631-781602ad, #w-node-_281ae0c8-c087-7d59-5ada-d56a51f2f632-781602ad {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d21a-e3b8d21a {

  grid-template-columns: 1fr 1fr;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d21a-e3b8d21ab {

  
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d21b-e3b8d21a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d21c-e3b8d21a {
  grid-template-rows: auto auto;
  grid-template-columns: .5fr 1fr;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d21d-e3b8d21a {
  grid-area: span 2 / span 1 / span 2 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d21e-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d221-e3b8d21a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d222-e3b8d21a {
  grid-template-rows: auto;
  grid-template-columns: .75fr 3.5fr;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d223-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d226-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d22d-e3b8d21a {
  grid-area: span 1 / span 1 ;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d22e-e3b8d21a {
  grid-template-rows: auto auto;
  grid-template-columns: .5fr 1fr;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d22f-e3b8d21a {
  grid-area: span 2 / span 1 / span 2 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d230-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d233-e3b8d21a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d234-e3b8d21a {
  grid-template-rows: auto;
  grid-template-columns: .75fr 3.5fr;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d235-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d238-e3b8d21a, #w-node-_1e2202d2-e629-88bf-5764-32b9ff4cd5b7-e3b8d21a, #w-node-b33d1ab9-58f0-2422-5d8b-b804a8ab5b87-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d241-e3b8d21a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d242-e3b8d21a {
  grid-template-rows: auto auto;
  grid-template-columns: .25fr 1fr;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d243-e3b8d21a {
  grid-area: span 2 / span 1 / span 2 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d245-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d248-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d24b-e3b8d21a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d24c-e3b8d21a {
  grid-template-rows: auto auto;
  grid-template-columns: .25fr 1fr;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d24d-e3b8d21a {
  grid-area: span 2 / span 1 / span 2 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d24f-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d252-e3b8d21a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d253-e3b8d21a {
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d254-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d257-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d25a-e3b8d21a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d25b-e3b8d21a {
  grid-template-rows: auto auto;
  grid-template-columns: .25fr 1fr;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d25c-e3b8d21a {
  grid-area: span 2 / span 1 / span 2 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d25e-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d261-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d264-e3b8d21a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d265-e3b8d21a {
  grid-template-rows: auto auto;
  grid-template-columns: .25fr 1fr;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d266-e3b8d21a {
  grid-area: span 2 / span 1 / span 2 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d268-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d26b-e3b8d21a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d26c-e3b8d21a {
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d26d-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d270-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d273-e3b8d21a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d274-e3b8d21a {
  grid-template-rows: auto auto;
  grid-template-columns: .25fr 1fr;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d275-e3b8d21a {
  grid-area: span 2 / span 1 / span 2 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d277-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d27a-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d27d-e3b8d21a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d27e-e3b8d21a {
  grid-template-rows: auto auto;
  grid-template-columns: .25fr 1fr;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d27f-e3b8d21a {
  grid-area: span 2 / span 1 / span 2 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d281-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d284-e3b8d21a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d285-e3b8d21a {
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d286-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d289-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d28c-e3b8d21a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d28d-e3b8d21a {
  grid-template-rows: auto auto;
  grid-template-columns: .25fr 1fr;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d28e-e3b8d21a {
  grid-area: span 2 / span 1 / span 2 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d290-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d293-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d296-e3b8d21a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d297-e3b8d21a {
  grid-template-rows: auto auto;
  grid-template-columns: .25fr 1fr;
}
#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d22e-e3b8d21ac {
  grid-template-columns: auto auto;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d298-e3b8d21a {
  grid-area: span 2 / span 1 / span 2 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d29a-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d29d-e3b8d21a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d29e-e3b8d21a {
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d29f-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2a2-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2a5-e3b8d21a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2a6-e3b8d21a {
  grid-template-rows: auto auto;
  grid-template-columns: .25fr 1fr;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2a7-e3b8d21a {
  grid-area: span 2 / span 1 / span 2 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2a9-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2ac-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2af-e3b8d21a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2b0-e3b8d21a {
  grid-template-rows: auto auto;
  grid-template-columns: .25fr 1fr;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2b1-e3b8d21a {
  grid-area: span 2 / span 1 / span 2 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2b3-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2b6-e3b8d21a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2b7-e3b8d21a {
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2b8-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2bb-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2be-e3b8d21a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2bf-e3b8d21a {
  grid-template-rows: auto auto;
  grid-template-columns: .25fr 1fr;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2c0-e3b8d21a {
  grid-area: span 2 / span 1 / span 2 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2c2-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2c5-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2c8-e3b8d21a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2c9-e3b8d21a {
  grid-template-rows: auto auto;
  grid-template-columns: .25fr 1fr;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2ca-e3b8d21a {
  grid-area: span 2 / span 1 / span 2 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2cc-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2cf-e3b8d21a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2d0-e3b8d21a {
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2d1-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2d4-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2d7-e3b8d21a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2d8-e3b8d21a {
  grid-template-rows: auto auto;
  grid-template-columns: .25fr 1fr;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2d9-e3b8d21a {
  grid-area: span 2 / span 1 / span 2 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2db-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2de-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2e1-e3b8d21a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2e2-e3b8d21a {
  grid-template-rows: auto auto;
  grid-template-columns: .25fr 1fr;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2e3-e3b8d21a {
  grid-area: span 2 / span 1 / span 2 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2e5-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2e8-e3b8d21a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2e9-e3b8d21a {
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2ea-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2ed-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2f0-e3b8d21a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2f1-e3b8d21a {
  grid-template-rows: auto auto;
  grid-template-columns: .25fr 1fr;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2f2-e3b8d21a {
  grid-area: span 2 / span 1 / span 2 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2f4-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2f7-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2fa-e3b8d21a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2fb-e3b8d21a {
  grid-template-rows: auto auto;
  grid-template-columns: .25fr 1fr;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2fc-e3b8d21a {
  grid-area: span 2 / span 1 / span 2 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d2fe-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d301-e3b8d21a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d302-e3b8d21a {
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d303-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d306-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d309-e3b8d21a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d30a-e3b8d21a {
  grid-template-rows: auto auto;
  grid-template-columns: .25fr 1fr;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d30b-e3b8d21a {
  grid-area: span 2 / span 1 / span 2 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d30d-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d310-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d313-e3b8d21a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d314-e3b8d21a {
  grid-template-rows: auto auto;
  grid-template-columns: .25fr 1fr;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d315-e3b8d21a {
  grid-area: span 2 / span 1 / span 2 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d317-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d31a-e3b8d21a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d31b-e3b8d21a {
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d31c-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d31f-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d322-e3b8d21a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d323-e3b8d21a {
 
  grid-template-columns: auto minmax(auto, 1fr);
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d324-e3b8d21a {
  grid-area: span 2 / span 1 / span 2 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d326-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d329-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d32c-e3b8d21a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d32d-e3b8d21a {
  grid-template-rows: auto auto;
  grid-template-columns:  .25fr minmax(auto, 1fr);
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d32e-e3b8d21a {
  grid-area: span 2 / span 1 / span 2 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d330-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d333-e3b8d21a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d334-e3b8d21a {
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d335-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d338-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d33b-e3b8d21a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d33c-e3b8d21a {
  grid-template-rows: auto auto;
  grid-template-columns: .25fr 1fr;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d33d-e3b8d21a {
  grid-area: span 2 / span 1 / span 2 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d33f-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d342-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d345-e3b8d21a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d346-e3b8d21a {
  grid-template-rows: auto auto;
  grid-template-columns: .25fr 1fr;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d347-e3b8d21a {
  grid-area: span 2 / span 1 / span 2 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d349-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d34c-e3b8d21a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d34d-e3b8d21a {
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
}

#w-node-acfb0cfc-423c-6793-641c-9f45e3b8d34e-e3b8d21a, #w-node-acfb0cfc-423c-6793-641c-9f45e3b8d351-e3b8d21a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-b0364071-d13e-b478-737c-9c451af13dd2-781602b3 {
  grid-template-rows: auto auto;
  grid-template-columns: .25fr 1fr;
}

#w-node-_881c90a1-0dbe-2d9e-94f2-cac4a2f095ce-781602b3 {
  grid-area: span 2 / span 1 / span 2 / span 1;
}

#w-node-_11bdbced-5070-35fc-3b3a-26df52403783-781602b3, #w-node-_2246a0da-db5a-8669-a7d1-48dae3a52d48-781602b3 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-d2c611e1-d0e4-fbde-15e9-89d67e2f1935-781602bd {
  grid-template-rows: auto;
  grid-template-columns: .5fr 1fr;
}

#w-node-_04bbb9aa-a22d-8c3b-884c-beb78fb5e5bf-781602bd, #w-node-a8007f7f-5f1c-16ae-cfe1-5e5c0a2393b2-781602bd {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_7353c4b0-05c9-aef4-9829-6b9a74915ea4-781602bd {
  grid-template-rows: auto;
  grid-template-columns: .25fr 1fr;
}
#w-node-_7353c4b0-05c9-aef4-9829-6b9a74915ea4-781602bdabout {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
}
#w-node-_7353c4b0-05c9-aef4-9829-6b9a74915ea5-781602bd, #w-node-_7353c4b0-05c9-aef4-9829-6b9a74915ea6-781602bd {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_2f42e6ca-4915-5d6b-8edb-e32aaf22ad6b-af22ad6b {
  grid-template-rows: auto auto auto auto auto minmax(auto, 1fr);
  grid-template-columns: .25fr 1fr .25fr 1fr;
}

#w-node-_2f42e6ca-4915-5d6b-8edb-e32aaf22ad6e-af22ad6b, #w-node-_2f42e6ca-4915-5d6b-8edb-e32aaf22ad71-af22ad6b, #w-node-_2f42e6ca-4915-5d6b-8edb-e32aaf22ad73-af22ad6b, #w-node-_2f42e6ca-4915-5d6b-8edb-e32aaf22ad76-af22ad6b, #w-node-_2f42e6ca-4915-5d6b-8edb-e32aaf22ad78-af22ad6b, #w-node-_2f42e6ca-4915-5d6b-8edb-e32aaf22ad7b-af22ad6b, #w-node-_2f42e6ca-4915-5d6b-8edb-e32aaf22ad7d-af22ad6b, #w-node-_2f42e6ca-4915-5d6b-8edb-e32aaf22ad80-af22ad6b, #w-node-_2f42e6ca-4915-5d6b-8edb-e32aaf22ad82-af22ad6b, #w-node-_2f42e6ca-4915-5d6b-8edb-e32aaf22ad85-af22ad6b, #w-node-_2f42e6ca-4915-5d6b-8edb-e32aaf22ad87-af22ad6b, #w-node-_2f42e6ca-4915-5d6b-8edb-e32aaf22ad8a-af22ad6b, #w-node-_2f42e6ca-4915-5d6b-8edb-e32aaf22ad8c-af22ad6b, #w-node-_2f42e6ca-4915-5d6b-8edb-e32aaf22ad8f-af22ad6b, #w-node-_2f42e6ca-4915-5d6b-8edb-e32aaf22ad91-af22ad6b, #w-node-_2f42e6ca-4915-5d6b-8edb-e32aaf22ad94-af22ad6b, #w-node-_2f42e6ca-4915-5d6b-8edb-e32aaf22ad96-af22ad6b, #w-node-_2f42e6ca-4915-5d6b-8edb-e32aaf22ad99-af22ad6b, #w-node-_2f42e6ca-4915-5d6b-8edb-e32aaf22ad9b-af22ad6b, #w-node-_2f42e6ca-4915-5d6b-8edb-e32aaf22ad9e-af22ad6b, #w-node-_2f42e6ca-4915-5d6b-8edb-e32aaf22ada0-af22ad6b, #w-node-_2f42e6ca-4915-5d6b-8edb-e32aaf22ada3-af22ad6b, #w-node-_2f42e6ca-4915-5d6b-8edb-e32aaf22ada4-af22ad6b {
  grid-area: span 1 / span 1 / span 1 / span 1;
}
#w-node-_2f42e6ca-4915-5d6b-8edb-e32aaf22ad6c-af22ad6b {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_62b23e3845c1a6d0f6be4e4900000000001b-781602c0 {
  margin-top: 20px;
  margin-bottom: 20px;
}

#w-node-_62b23e3845c1a6d0f6be4e49000000000020-781602c0 {
  border: 1px solid #e6e6e6;
}

#w-node-_62b23e3845c1a6d0f6be4e49000000000021-781602c0 {
  margin-top: 10px;
}

#w-node-_62b23e3845c1a6d0f6be4e49000000000024-781602c0 {
  position: initial;
  margin-left: 0;
  margin-right: 0;
}

#w-node-_8ebd84fe-3c7e-f6a3-8586-0626b58ebcc3-781602c0 {
  grid-template-rows: auto;
  grid-template-columns: 2.5fr;
}

#w-node-_8ebd84fe-3c7e-f6a3-8586-0626b58ebcc5-781602c0 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_2e0c6294-c2d7-12ea-42cc-b3681e79bcd4-781602c0 {
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
}

#w-node-_2e0c6294-c2d7-12ea-42cc-b3681e79bcd5-781602c0, #w-node-_2e0c6294-c2d7-12ea-42cc-b3681e79bcd6-781602c0, #w-node-_0db0bb66-e2eb-1c82-0907-42ec80b42be2-781602c0 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_920389c5-3153-4f5b-c09c-8be9c5e51da8-781602c0 {
  grid-template-rows: auto;
  grid-template-columns: .25fr 1fr;
}

#w-node-_920389c5-3153-4f5b-c09c-8be9c5e51da9-781602c0, #w-node-_920389c5-3153-4f5b-c09c-8be9c5e51daa-781602c0, #w-node-_78a06bbb-0166-41b8-c633-7180a57c1ee4-781602c0 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_7bd82a61-f2bf-f4e9-7606-b93c41b6337c-52a0fb91 {
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
}

#w-node-_7bd82a61-f2bf-f4e9-7606-b93c41b6337d-52a0fb91, #w-node-_7bd82a61-f2bf-f4e9-7606-b93c41b6337e-52a0fb91, #w-node-_6bf55c20-8eaa-347f-e5dc-bf7362f4ec23-52a0fb91 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_7b178e0e-7778-3cb3-3089-661948c03a79-52a0fb91 {
  grid-template-rows: auto;
  grid-template-columns: .25fr 1fr;
}

#w-node-_7b178e0e-7778-3cb3-3089-661948c03a7a-52a0fb91, #w-node-_7b178e0e-7778-3cb3-3089-661948c03a7b-52a0fb91, #w-node-_45d6a45d-6fa6-43cb-fc34-bfe8b3fc3e7e-52a0fb91 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_4216035e-6416-4b70-dd03-3e292dc9f2f6-96dc377f {
  grid-template-rows: auto;
  grid-template-columns: 1fr;
}

#w-node-bc7dc6ea-5844-85ac-2c94-664ddcd62d67-96dc377f {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_4216035e-6416-4b70-dd03-3e292dc9f2f6-cc44b97c {
  grid-template-rows: auto;
  grid-template-columns: 1fr;
}

#w-node-bc7dc6ea-5844-85ac-2c94-664ddcd62d67-cc44b97c, #w-node-b49c48ad-b3b4-f319-67e3-9d04be5835dc-52bb701f {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

@media screen and (min-width: 1280px) {
  #w-node-_3385dc3a-c504-929a-7217-463586dd5d8a-7816029e {
    justify-self: auto;
  }

  #w-node-d2c611e1-d0e4-fbde-15e9-89d67e2f1935-781602bd {
    grid-template-rows: auto;
    grid-template-columns: .5fr 1.25fr;
  }
}

@media screen and (max-width: 767px) {
  #w-node-_2c511369-eabb-a0ff-6024-6e8ecffbdf82-7816026e {
    order: -9999;
  }
}

@media screen and (max-width: 479px) {
  #w-node-_2f42e6ca-4915-5d6b-8edb-e32aaf22ad6b-af22ad6b {
    grid-template-rows: auto auto auto auto auto minmax(auto, 1fr);
    grid-template-columns: .2fr .5fr .2fr .5fr;
  }

  #w-node-_2f42e6ca-4915-5d6b-8edb-e32aaf22ada3-af22ad6b {
    order: 9999;
  }

  #w-node-_7bd82a61-f2bf-f4e9-7606-b93c41b6337c-52a0fb91 {
    grid-template-rows: auto auto;
    
  }
}


